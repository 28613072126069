import { useContext, useEffect, useState } from "react";
import { UserInfoContext } from "../auth/user-validation";


export function CourseSection(prop: any) {

  const ctx = useContext(UserInfoContext);
  const userInfo = ctx.userInfo ;

  const scheduleType: 'teacher' | 'class' = prop.scheduleType || 'teacher';

  // console.log({ prop })


  if (prop.isEmpty === 'true') {
    return (
      <div className={`rounded-lg p-2 mb-3 h-[68px] bg-slate-50`}></div>
    );
  }

  return (
    <div className={`rounded-lg p-2 mb-3 h-[68px] ${prop.bgColor}`}>
      <div className="font-bold text-lg whitespace-nowrap">{prop.subject}</div>
      <div className="flex justify-between">
        <div className="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
            className="text-black me-1">
            <path fill="currentColor"
              d="M12 2C7.589 2 4 5.589 4 9.995C3.971 16.44 11.696 21.784 12 22c0 0 8.029-5.56 8-12c0-4.411-3.589-8-8-8zm0 12c-2.21 0-4-1.79-4-4s1.79-4 4-4s4 1.79 4 4s-1.79 4-4 4z" />
          </svg>
          <div>{prop.classroom}</div>
        </div>

        <div className="flex items-center">
          {(scheduleType === 'teacher' && userInfo?.roleType !== 'student') && (
            <>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 48 48"
                className="text-black me-1">
                <path fill="currentColor"
                  d="M17 24c3.867 0 7-3.133 7-7s-3.133-7-7-7s-7 3.133-7 7s3.133 7 7 7Zm22-3.5c0 3.039-2.461 5.5-5.5 5.5a5.499 5.499 0 0 1-5.5-5.5c0-3.039 2.461-5.5 5.5-5.5s5.5 2.461 5.5 5.5ZM17 26c2.734 0 7.183.851 10.101 2.545C28.293 29.758 29 31.081 29 32.4V38H4v-5.6c0-4.256 8.661-6.4 13-6.4Zm27 12H31v-5.6c0-1.416-.511-2.72-1.324-3.883c1.541-.345 3.058-.517 4.217-.517C37.62 28 44 29.787 44 33.333V38Z" />
              </svg>
              <div className="mr-2">{prop.classname}</div>
            </>
          )}
           {(scheduleType === 'class' || userInfo?.roleType === 'student') && (
            <>              
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 48 48"
                    className="text-black me-1">
                    <path fill="#000" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"
                      d="M24 20a7 7 0 1 0 0-14a7 7 0 0 0 0 14ZM6 40.8V42h36v-1.2c0-4.48 0-6.72-.872-8.432a8 8 0 0 0-3.496-3.496C35.92 28 33.68 28 29.2 28H18.8c-4.48 0-6.72 0-8.432.872a8 8 0 0 0-3.496 3.496C6 34.08 6 36.32 6 40.8Z" />
                  </svg>

              <div>{prop.teacher_name}</div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}