import { useContext, useEffect, useState } from "react";
// import { UserInfoContext } from "../auth/user-validation";
// import { DailySchedule } from "./daily_schedule_bk";
import dayjs from "dayjs";
import { Tab, initTE, Input } from "tw-elements";
import { PeriodTime } from "./period-time";
import { WeeklyScheduleHeader } from "./weekly-schedule-header";
import { DailySchedule } from "./daily-schedule";
import { getMyClass, getMyDailySchedule, getMyWeeklySchedule, getClassDailySchedule, getClassWeeklySchedule , getSchoolDates } from "../../dal/schedule";
import { CourseSections } from "../../dal/course_sections";
import { UserInfoContext } from "../auth/user-validation";
import { WeekDays, Periods } from "../../dal/school_settings";
import { WeeklySchedulePerWeekday } from "./weekly-schedule-per-weekday";

initTE({ Tab, Input });

/**
 * 顯示使用者的週課表
 * @returns 
 */
function WeeklySchedulePage() {

  const ctx = useContext(UserInfoContext);

  const [myClasses, setMyClasses] = useState<any[]>([]) // 我的導師班
  const [selectedScheduleType, setSelectedScheduleType] = useState('0');  // 選擇課表類型
  const [currentWeekday, setCurrentWeekday] = useState<number>(new Date().getDay())  // 選擇的星期，預設是今天
  const [weeklySchedule, setWeeklySchedule] = useState<any[]>([]);  // 當下報表類型的周課表
  const [courseSectionInfosByWeekday, setCourseSectionInfosByWeekday] = useState<any>({}) // 根據 datesOfWeek 裡每一天的課程分段，用來產生課表畫面。
  

  /** 0. 取得使用者資料，以及如果是教師，則取得我的班級資料，已完成課表類型的下拉選項 */
  useEffect( () => {

    const userInfo = ctx.userInfo ;
    // console.log( { userInfo });
    if (userInfo?.roleType === 'teacher') {
      getMyClass().then(classes => {
        // console.log({ classes: classes.class });
        setMyClasses(classes.class); 
      })
    }
  }, [ctx.userInfo]);

  /** 1. 當課表類型變動時，日課表與周課表 */
  useEffect( ()=> {
    loadScheduleData();
  }, [selectedScheduleType])


  /** 2. 當週課表來源變動時，重新計算畫面資料 */
  useEffect(() => {
    handleSelectWeekday(currentWeekday)
  }, [weeklySchedule])

  
  // 取得週課表資料
  const loadScheduleData = async () => {
    const p2 = new Promise(async ( resolve, rej) => { 
        try {
          const result: any[] = (selectedScheduleType === '0') ? (await getMyWeeklySchedule()) : (await getClassWeeklySchedule(selectedScheduleType));
          resolve(result);
        } catch ( ex) {
          rej(ex);
        }
      });

      Promise.all([ p2 ]).then( values => {
          setWeeklySchedule(values[0] as any[])
      })
  };

  /** 找出一星期中每一天的所有節次  */
  const mergeCourseSections = () => {
    const crsSections = new CourseSections([], weeklySchedule as any[]);
    const courseSectionInfos: any = {};
    
    // 對於一星期中的每一天
    WeekDays.forEach( wd => {

      // 找出某一天的所有節次資訊
      const sections = crsSections.getSectionsByWeekday(wd.day);
      // console.log({ sections });

      courseSectionInfos[wd.day] = sections ; // 放到 dictionary 中

      // 2.3 判斷本日是否有課程
      const validSections = sections.filter( (sec: any) =>  (sec.dailyCourseSection || sec.weeklyCourseSection ))
      wd.hasSections = (validSections.length > 0)
    })

    return courseSectionInfos ;
  }


  /** 當使用者選了日曆上的星期幾 */
  const handleSelectWeekday = (weekday: number) => {
    // console.log({ date, type: (typeof date) })

    // const targetDate = dayjs(date);
    setCurrentWeekday(weekday);

    // const targetDates = parseWeekDays(date);
    const courseSectionInfos = mergeCourseSections()
    setCourseSectionInfosByWeekday(courseSectionInfos);
  }

  const convertScheduleTypeText = (scheduleTypeNum: string): string => {
    return scheduleTypeNum === '0' ? 'teacher' : 'class'
  }


  return (
    <>
      <div className="containers p-4">
        <div className="grid grid-cols-1 md:grid-cols-3 items-center">
          <div className="col-start-1 md:col-start-2 text-lg sm:text-xl bg-point mx-auto mb-5">{ctx.userInfo?.schoolYear}學年{ctx.userInfo?.semester === 1 ? '上' : '下'}學期週課表</div>
          <select id="small" className="block p-2 mb-5 color-p4 text-center w-64 mx-auto border border-[#9bb0d3]" value={selectedScheduleType} onChange={(e) => setSelectedScheduleType(e.target.value)}>
            <option value="0">{ctx.userInfo?.name}{ctx.userInfo?.roleType === 'teacher' ? '老師': ''} 的課表</option>
            { myClasses.map( cls => <option key={cls.classID} value={cls.classID}>{cls.className}班級課表</option> )}
          </select>
        </div>

        {/* === Calendar === */}
        {/* <div className="flex justify-center items-center gap-14 mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" className="cursor-pointer" onClick={previousWeek}>
            <path fill="currentColor" d="m4 10l9 9l1.4-1.5L7 10l7.4-7.5L13 1z" />
          </svg>
          <input type="date" className="w-[120px] p-0 shadow-none" value={currentDate} onChange={(e) => { setCurrentDate(e.target.value); handleSelectDate(dayjs(e.target.value).toDate()) }} />
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" className="cursor-pointer"  onClick={nextWeek}>
            <path fill="currentColor" d="M7 1L5.6 2.5L13 10l-7.4 7.5L7 19l9-9z" /> 
          </svg>
        </div> */}

        <div className="w-full overflow-x-auto">
          <div className="min-w-1200">

            {/* Tabs navigation */}
            <ul className="flex justify-between gap-x-1 sm:gap-x-3 text-center text-sm sm:text-base mb-5 list-none border-b-2 border-gray-300 pl-0"
              role="tablist" data-te-nav-ref>
              {/*  周日無課  */}
              {WeekDays.map(wd => (
                <WeeklyScheduleHeader 
                    key={wd.day}
                    weekday={wd} 
                    onSelected={handleSelectWeekday} 
                    hasSections={ weeklySchedule.filter( ws => ws.weekday === wd.day).length > 0} 
                    isActive={wd.day === currentWeekday}
                    />
              ))}
            </ul>

            {/* Tabs content */}
            <div className="flex justify-between gap-0 sm:gap-3 mb-6">
              <div className="sticky left-0 bg-white whitespace-nowrap shrink-0 w-auto me-3 sm:me-0">
                {Periods.map(p => (
                  <PeriodTime key={p.period} period={p.period} begin_time={p.begin_time} end_time={p.end_time} /> 
                ))}

              </div>

            {
              WeekDays.map(wd => {
                const week_day = wd.day;
                const sections = courseSectionInfosByWeekday[wd.day];
                const validSections = sections ? sections.filter( (sec: any) => (sec.dailyCourseSection || sec.weeklyCourseSection )) : [];
                // console.log( { dtText, week_day, dt, validSections })
                if ((week_day === 0 || week_day === 6) && validSections.length === 0) {
                  return ('');
                } else {
                  return (<WeeklySchedulePerWeekday key={wd.day} weekday={week_day} isActive={wd.day === currentWeekday} sections={sections} scheduleType={convertScheduleTypeText(selectedScheduleType)} />);
                }
              })
            }
              <div></div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default WeeklySchedulePage;