import dayjs from "dayjs";
import { CourseSection } from "./course-section";
import { CourseSectionInfo } from "../../dal/course_sections";

export function WeeklySchedulePerWeekday({weekday, isActive, scheduleType, sections}: { weekday: any, isActive: boolean, scheduleType: string, sections: CourseSectionInfo[]}) {

  // console.log({ weekday, isActive, scheduleType, sections })

  return (
    <div className={`hidden sm:block data-[te-tab-active]:block tabstyle text-white w-full overflow-hidden ${isActive ? 'opacity-100':'opacity-0'}`}
      style={{ opacity: 1 }} id={`tabs-${weekday}`} role="tabpanel" aria-labelledby="tabs-mon-tab" {...isActive && {'data-te-tab-active': ''}} >

      { sections && sections.map ( (sec: CourseSectionInfo) => {
          if (sec.dailyCourseSection || sec.weeklyCourseSection ) {
            // console.log({ sec , teachers: (sec.teachers ? sec.teachers : {})})
            const teacherNames = sec.teachers ? sec.teachers.map( t=> t.teacherName).join(',') : '';
            // console.log({ teacherNames })
            return (<CourseSection key={`${sec.weekday}_${sec.period}`} period_no={sec.period} classname={sec.className} classroom={sec.classroomName} subject={sec.subject} teacher_name={teacherNames} scheduleType={scheduleType} isEmpty="false" weekday={weekday} bgColor={sec.bgColor} />)
          } else  {
            return (
              <CourseSection key={`${sec.weekday}_${sec.period}`} period_no={sec.period}  isEmpty="true" weekday={weekday}  /> 
            )
          }
        })
      }
      
      {/* <CourseSection period_no="1" classname="305班" classroom="102教室" subject="國文" teacher_name="王曉明" scheduleType="teacher" isEmpty="false" weekday={prop.weekday} />

      <CourseSection period_no="2" classname="305班" classroom="101教室" subject="數學" teacher_name="王曉明" scheduleType="class" isEmpty="false" weekday={prop.weekday}  />

      <CourseSection period_no="3"  isEmpty="true" weekday={prop.weekday}  />

      <CourseSection period_no="3" classname="311班" classroom="101教室" subject="英文" teacher_name="王曉明" scheduleType="teacher" isEmpty="false" weekday={prop.weekday}  /> */}


    </div>
  )
}