

/** 定義可能的背景色 */
const bgColors = ['bg-01', 'bg-02', 'bg-03', 'bg-04', 'bg-05', 'bg-06', 'bg-07', 'bg-08'];

/** 預定科目的顏色 */
const subjColors = [
    { name: 'bg-01', keywords: ['國文', '國語', '國語文']},
    { name: 'bg-03', keywords:  ['英文', '英語']},
    { name: 'bg-02', keywords:  ['數學']},
    { name: 'bg-04', keywords:  ['自然', '物理', '科技', '生活']},
    { name: 'bg-05', keywords:  ['社會', '歷史']},
    { name: 'bg-06', keywords:  ['化學', '地理']},
    { name: 'bg-07', keywords:  ['生物', '公民']},
    { name: 'bg-08', keywords:  ['地科', '地球科學']}
]

/** 找出是否有指定科目的顏色 */
const findSubjectColor = (subjName: string) => {

    const colors = subjColors.filter( color => {
        let result = false ;
        for (const subjKeyword of color.keywords) {
            result = (subjName.indexOf(subjKeyword) > -1)
            if (result) { break ;}
        }
        return result ;
    })

    return (colors.length === 0) ? undefined : colors[0].name  ;
}

/** 從字串計算出所有字元總和 */
const calculateNumberFromStrimg = (rawString: string): number => {

    let sum = 0;
    
    for (let i = 0; i < rawString.length; i++) {
        sum += rawString.charCodeAt(i)
    }
    return sum;
}

/** 根據指定字串計算背景顏色 */
export const calculateBgColor = (rawString: string): string => {

    // return 'bg-02';
    
    // 先找出是否是預設的科目顏色
    const color = findSubjectColor(rawString);
    if (color) { 
        // console.log( ` subj_a: ${rawString}, color: ${color} `)
        return color ;
    }

    // 如果找不到預設科目的顏色，則計算科目名稱的數字，找出對應的顏色
    const stringNum = calculateNumberFromStrimg(rawString);
    const colorCount = bgColors.length > 0 ? bgColors.length : 8 ;
    const index = stringNum % colorCount;
    // console.log( ` subj_b: ${rawString}, color: ${bgColors[index]} ` );
    return bgColors[index];
}