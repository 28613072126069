import React, { Children } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';


import './index.css';
// import App from './App';
import  UserValidation from './components/auth/user-validation';
import Root from './routes/root';
import RouteErrorPage from './routes/route-error-page';
import { About } from './components/about/about';
import NotSigninPage from './components/auth/not-signin-page';
import MainPage from './components/main';
import SchedulePage from './components/schedule/schedule';
import WeeklySchedulePage from './components/schedule/weekly-schedule';



/** 
 * 建立 Router 
 * 參考：https://reactrouter.com/en/main/start/tutorial
 * */
const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <RouteErrorPage />,
    children: [
      {
        path: 'main',
        element: <MainPage />
      },
      {
        path: 'weekly_schedule',
        element: <WeeklySchedulePage /> 
      },
      {
        path: 'schedule',
        element: <SchedulePage /> 
      },
      {
        path: 'about',
        element: <About />
      },
      {
        path: 'auth/not-signin',
        element: <NotSigninPage />
      }
    ]
  },
]);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
    <RouterProvider router={router} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
