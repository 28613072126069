import { useContext, useEffect, useState } from "react";
import { UserInfoContext } from "../auth/user-validation";
import * as scheduleService from "../../dal/schedule";
import dayjs from "dayjs";

export function WeeklyScheduleHeader({weekday, hasSections, isActive,  onSelected}: { weekday: any, hasSections: boolean, isActive: boolean, onSelected: (weekday: any) => void}) {

  // console.log({ weekday, hasSections, isActive });

  // const { userInfo } = useContext(UserInfoContext)

  // console.log(({ userInfo })); 
  
  // const dj = dayjs(prop.date);
  // const weekdayText = dj.format('ddd');
  // const dj2 = dayjs(prop.dateToShowCourse); // 用來判斷這個日期是否是被選取的日期
  // // console.log({ comp: 'WeekdayHeader', date: prop.date , dj: dj.format('YYYYMMDD'), dj2: dj2.format('YYYYMMDD') })
  // const isActive = (dj.format('YYYYMMDD') === dj2.format('YYYYMMDD'));

  // console.log({ hasSections: prop.hasSections });

  const isWeekend = (weekday.day === 0 || weekday.day === 6);

  const handleClick = () => {
    onSelected && onSelected(weekday.day); 
  }

  return (
    <>
      { (weekday.day === 0) && <li role="presentation" className={`w-0 sm:w-[${(hasSections) ? '82' : '60'}px] shrink-0`}></li>}
      <li role="presentation" className={`${(isWeekend && !hasSections) && 'sm:w-auto'} w-full`} onClick={handleClick}>
        <a href={`#tabs-${weekday.day}`}
          className={`${isWeekend ? 'pointer-events-none text-red-400' : 'text-neutral-500'} 
            max-w-[86px] my-2 block py-3 font-medium leading-tight hover:isolate hover:bg-neutral-100 hover:text-gray-900 
            hover:data-[te-nav-active]:bg-orange-400 data-[te-nav-active]:bg-p1 data-[te-nav-active]:text-white mx-auto shrink-0`}
          data-te-toggle="pill"
          data-te-target={`#tabs-${weekday.day}`}
          role="tab"
          aria-controls={`tabs-${weekday.day}`}
          {...isActive && (!isWeekend) &&  { 'data-te-nav-active': '' }}
          {...isActive && (isWeekend && hasSections) &&  { 'data-te-nav-active': '' }} >

          {/* <div>{dj.date()}</div> */}
          <div>{weekday.text}</div>
        </a>
      </li>
    </>
  )
}